import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../logo.png';
import { sendCalendarLinkToCorporateAccountEmail } from './Api';
import axios from 'axios';

export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const LinkSender = () => {
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(0);
  const [wasSent, setWasSent] = useState(0);
  const onConfirmClick = async () => {
    if (email.match(emailRegex)) {
      // POST
      setIsSending(1);
      // POST returns:
      let response: any = {};
      try {
        response = await axios.post(sendCalendarLinkToCorporateAccountEmail, {
          email,
        });
      } catch (err) {
        console.error(err);
        setIsSending(0);
        alert(JSON.stringify(err));
      }
      //debugger;
      if (response.data?.data?.error) {
        alert(response.data.data.error);
        setIsSending(0);
        return;
      }
      setTimeout(() => {
        setWasSent(1);
      }, 1000);
    }
  };

  const defaultBlock = (
    <div>
      <div>
        Please enter your work email address and we'll send you an email to book
        sessions at Silofit
      </div>
      <input
        type="text"
        placeholder="Enter work email address"
        value={email}
        autoFocus={true}
        className="primaryInput"
        onChange={e => {
          const email = e.target.value;
          setEmail(email);
        }}
      />

      <button className="primarySiloButton" onClick={onConfirmClick}>
        {isSending ? 'Sending...' : 'Send me my access link'}
      </button>
    </div>
  );

  const successBlock = (
    <div>
      Email sent to <b>{email}</b>
      <br />
      Please check your inbox, you can close this window.
    </div>
  );
  const content = wasSent ? successBlock : defaultBlock;
  return (
    <div className="unlogged">
      <div className="logoWrapper">
        <img src={logo} alt="Silofit" />
      </div>
      <div className="emailDetails">{content}</div>
    </div>
  );
};

export default LinkSender;
