import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../logo.png';
import moment from 'moment-timezone';
import { handleCorporateCalendarRegistrationEndpoint } from './Api';
import { emailRegex } from './LinkSender';
import axios from 'axios';

const Attendee = state => {
  const {
    attendee,
    own_email,
    attendees,
    setNeedsEmail,
    formattedDate,
    setIsConfirming,
    setIsWrongUrl,
    isAdmin,
    daySlot,
    waitlist,
    loggedAs,
    isWaitlist,
    cID,
    cHash,
    cKey,
  } = state;

  const handleClickRegisterAttendee = () => {
    if (!own_email && !isAdmin && !cKey) {
      setIsWrongUrl(1);
      return;
    }
    if (!own_email && isAdmin && !isWaitlist) {
      setNeedsEmail(1);
    } else {
      // Todo: for admin maybe it's not own email as
      // we prompt every time?
      bookAndUpdateCalendar({
        email: own_email,
        action: 'book',
        formattedDate,
        isWaitlist,
        setIsConfirming,
        cID,
        daySlot,
        cHash,
        cKey,
        uid: state.user.uid,
      });
    }
  };

  const handleClickUnRegisterAttendee = () => {
    // Todo: for admin maybe it's not own email as
    // we prompt every time?
    bookAndUpdateCalendar({
      email: attendee,
      isWaitlist,
      action: 'cancel',
      formattedDate,
      setIsConfirming,
      cID,
      daySlot,
      cHash,
      cKey,
      uid: state.user.uid,
    });
  };

  const takenBlock = <div className="takenBlock">{attendee}</div>;

  const ownBlock = (
    <div className="takenBlock ownBlock">
      {attendee}{' '}
      <button onClick={handleClickUnRegisterAttendee} className="ownRemove">
        𝘅 Cancel Booking
      </button>
    </div>
  );

  const isInWaitlist = waitlist.includes(loggedAs);
  const isInWaitlistLabel = isInWaitlist
    ? `Leave the waitlist (${waitlist.length})`
    : `${isAdmin ? '' : 'Join the'} waitlist (${waitlist.length})`;

  const registerBlockLabel = isWaitlist ? isInWaitlistLabel : 'Book now';
  const registerBlock = isInWaitlist ? (
    <button className="registerBlock" onClick={handleClickUnRegisterAttendee}>
      {registerBlockLabel}
    </button>
  ) : (
    <button className="registerBlock" onClick={handleClickRegisterAttendee}>
      {registerBlockLabel}
    </button>
  );

  let renderBlock;
  if (attendee) {
    if (
      (attendee && attendee === own_email) ||
      attendee === state.loggedAs ||
      state.isAdmin
    ) {
      renderBlock = ownBlock;
    } else {
      renderBlock = takenBlock;
    }
  } else {
    // No attendee
    //  debugger;
    //console.log('Attendeees:', attendees);
    if (!isWaitlist && isAttending(attendees, own_email, state.loggedAs)) {
      renderBlock = <div className="emptyBlock">EMPTY</div>;
    } else {
      renderBlock = registerBlock;
    }
  }
  return <div className="attendee">{renderBlock}</div>;
};

// Define if someone is attending
const isAttending = (attendees, own_email, loggedAs) =>
  attendees.includes(own_email) || attendees.includes(loggedAs);

const buildFullDate = (date, calendar_days, daySlot = false) => {
  let day;
  if (daySlot) {
    day = daySlot;
  } else {
    day = calendar_days[date.format('ddd')];
  }
  const { start, end } = day;
  const formattedStart = date
    .hours(start[0])
    .minutes(start[1])
    .format('hh:mma');
  const formattedEnd = date
    .hours(end[0])
    .minutes(end[1])
    .format('hh:mma');
  return {
    formattedDate: date.format('ddd Do MMM'),
    formattedStartEnd: `${formattedStart} - ${formattedEnd}`,
  };
};

// Request for an email on a specific day then
// Updates the state
// Maybe transform this into a callable directly from SDK
// So Auth is forwarded
const bookAndUpdateCalendar = async ({
  email,
  action,
  isWaitlist,
  formattedDate,
  setIsConfirming,
  cID,
  cHash,
  daySlot = null,
  cKey = null,
  uid,
}) => {
  let response;
  setIsConfirming(1);
  try {
    response = await axios.post(handleCorporateCalendarRegistrationEndpoint, {
      email,
      action,
      isWaitlist,
      formattedDate,
      cID,
      cHash,
      cKey,
      daySlot,
      uid,
    });
  } catch (err) {
    console.error(err);
  }
  if (response?.error) {
    alert(response.error);
  }
  if (response?.data?.data?.error) {
    alert(response.data.data.error);
  }
  setIsConfirming(0);
};

const padStrTo2 = num => (num.toString().length < 2 ? '0' + num : '' + num);
const padAndJoin = arr => arr.map(padStrTo2).join('');

const DayBuilder = state => {
  //console.log(state.date);
  const {
    start,
    end,
    calendarBooked,
    date,
    domain_name,
    own_email,
    isAdmin,
    max_capacity,
    calendar_days,
    setOwnEmail,
    loggedAs,
    daySlot,
    setCalendarData,
  } = state;

  const [needsEmail, setNeedsEmail] = useState(0);
  const [email, setEmail] = useState('');
  const [isConfirming, setIsConfirming] = useState(0);

  let formattedDate = date.format('YYYYMMDD');

  let calendarDaysDate = calendarBooked[formattedDate] || {};

  if (daySlot)
    calendarDaysDate =
      calendarBooked[
        formattedDate + padAndJoin(daySlot.start) + padAndJoin(daySlot.end)
      ] || {};

  let { attendees = [], code, waitlist = [] } = calendarDaysDate;
  //debugger;

  const buildedFullDate = buildFullDate(date, calendar_days, daySlot);
  const attendeesFinalArray = Array.from(Array(max_capacity), (el, index) => {
    return (
      <Attendee
        key={index}
        {...state}
        waitlist={waitlist}
        formattedDate={formattedDate}
        setIsConfirming={setIsConfirming}
        setNeedsEmail={setNeedsEmail}
        attendees={attendees}
        daySlot={daySlot}
        attendee={attendees[index] || false}
      />
    );
  });

  // Default block, showing attendees
  const isAttendingFlag = isAttending(attendees, own_email, loggedAs);

  // If it's full and i'm not attending, add a waitlist block
  if (attendees.length == max_capacity && (!isAttendingFlag || isAdmin)) {
    attendeesFinalArray.push(
      <Attendee
        key="waitlist"
        {...state}
        waitlist={waitlist}
        isWaitlist={true}
        formattedDate={formattedDate}
        setIsConfirming={setIsConfirming}
        setNeedsEmail={setNeedsEmail}
        attendees={attendees}
        attendee={false}
      />
    );
  }

  let access_code_block;
  if (!code) {
    access_code_block = 'NOT READY';
  } else {
    if (isAttendingFlag) {
      // Attending and code
      access_code_block = code;
    } else {
      // Not attending but code
      access_code_block = 'ONLY FOR ATTENDEES';
    }
  }

  let contentBlock = (
    <div className="attendees_code_wrapper">
      <div className="attendees">{attendeesFinalArray}</div>
      <div className="codeWrapper">
        <div className={code ? 'present' : 'missing'}>
          Access code: {access_code_block}
        </div>
      </div>
    </div>
  );

  if (needsEmail) {
    const isValid =
      domain_name.some(domain => email.endsWith(domain)) &&
      email.match(emailRegex);

    const onConfirmClick = async () => {
      if (isValid) {
        // Reserve a slot for this date with this email
        //setOwnEmail(email);
        //localStorage.setItem('own_email', email);
        setNeedsEmail(0);
        // And update the calendar
        const { cID, cHash, cKey } = state;
        await bookAndUpdateCalendar({
          email,
          action: 'book',
          isWaitlist: false,
          formattedDate,
          setIsConfirming,
          daySlot,
          cID,
          cHash,
          cKey,
          uid: state.user.uid,
        });
        setEmail('');
      }
    };
    const placeholder = `email@${domain_name}`;
    const cNames = `primarySiloButton ${isValid ? 'valid' : 'disabled'}`;
    contentBlock = (
      <div className="askemail_wrapper">
        <div>
          <div className="enterCorporate">
            Please enter {isAdmin ? 'a' : 'your'} corporate email
          </div>
          <input
            type="text"
            placeholder={placeholder}
            value={email}
            autoFocus={true}
            className={`primaryInput ${isValid ? 'valid' : 'invalid'}`}
            onChange={e => {
              const email = e.target.value;
              setEmail(email);
            }}
          />

          <button className={cNames} onClick={onConfirmClick}>
            Confirm and reserve
          </button>

          <button
            className="primarySiloButton primarySiloButton--noborder"
            onClick={() => {
              setNeedsEmail(0);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className={`oneday ${isConfirming ? 'confirming' : ''}`}>
      <div className="fulldate">
        {buildedFullDate.formattedDate}
        <br />
        {buildedFullDate.formattedStartEnd}
      </div>
      {contentBlock}
    </div>
  );
};

export default DayBuilder;
