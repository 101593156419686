import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../logo.png';
import moment from 'moment-timezone';
import CalendarDaysDisplayer, { getStartEnd } from './CalendarDaysDisplayer';
import LinkSender from './LinkSender';

export const getCorporateID = pathname => pathname.split('/')[2];
export const getCorporateHash = pathname => pathname.split('/')[1];
export const getCorporateUserKey = pathname => pathname.split('/')[3];

interface wrapperState {
  pathname: string;
  firebaseApp: firebase.app.App;
}

const myWindow: any = window;

const MainWrapper = (state: wrapperState) => {
  console.log(state);
  const [calendarData, setCalendarData] = useState();

  // These will be switched if the cID or cHash is wrong, we'll
  // ask for the user email and shoot him an email with his corporate link
  // slack style
  const [isWrongURL, setIsWrongUrl] = useState(0);
  const [loggedAs, setLoggedAs] = useState('');

  const cID = getCorporateID(state.pathname);
  const cHash = getCorporateHash(state.pathname);
  const cKeyTmp = getCorporateUserKey(state.pathname);
  const [cKey, setCkey] = useState(cKeyTmp);
  console.log(`cID:${cID} - cHash: ${cHash} - cKey ${cKey}`);
  // Might not work different timezones
  useEffect(() => {
    async function fetchData() {
      const hashSnapshot = await state.firebaseApp
        .database()
        .ref(`/corporate_accounts/${cID}/hash`)
        .once('value');
      console.log(cID, cHash);
      console.log(`HashSnapshot:`, hashSnapshot.val());

      // We need to validate the regex after based on the corporate regex email
      // Update: Several emails can now be part of a single company
      const corporate_domain_to_account_snapshot = await state.firebaseApp
        .database()
        .ref('/corporate_domain_to_account')
        .orderByChild('corporate_account_key')
        .equalTo(cID)
        .once('value');

      const corporate_domain_to_account = await corporate_domain_to_account_snapshot.val();
      console.log(
        `Corporates domain accounts | Resolved to entries: ${JSON.stringify(
          corporate_domain_to_account
        )}`
      );
      const allowed_domains = Object.keys(corporate_domain_to_account).map(
        (key: any) => corporate_domain_to_account[key].domain_name
      );

      // const regexSnapshot = await state.firebaseApp
      //   .database()
      //   .ref(`/corporate_accounts/${cID}/domain_name`)
      //   .once('value');
      const domain_name = allowed_domains;

      // https://stackoverflow.com/questions/39076284/firebase-orderbykey-with-startat-and-endat-giving-wrong-results
      let isAdmin = false;
      if (hashSnapshot.exists() && cHash == hashSnapshot.val()) {
        if (cKey) {
          const emailSnap = await state.firebaseApp
            .database()
            .ref(
              `/corporate_accounts/${cID}/calendar_ref_to_email/${cKey}/email`
            )
            .once('value');
          const email = emailSnap.val();
          if (email) {
            setLoggedAs(email);
            myWindow.Intercom('update', { email });
            const contactSnap = await state.firebaseApp
              .database()
              .ref(`/corporate_accounts/${cID}/contact`)
              .once('value');
            const contact = contactSnap.val();
            // If we're the contact for the company we're admin
            if (contact === email) {
              isAdmin = true;
            }
          } else {
            console.log(`Unsetting ckey since invalid for ${cKey}, ${cID}`);
            setCkey(null);
          }
        }

        const hasMultipleSlotsForDaySnap = await state.firebaseApp
          .database()
          .ref(`/corporate_accounts/${cID}/multiple_slots_per_day`)
          .once('value');
        const hasMultipleSlotsForDay = hasMultipleSlotsForDaySnap.val();

        const firstSessionDateSnap = await state.firebaseApp
          .database()
          .ref(`/corporate_accounts/${cID}/first_session_date`)
          .once('value');
        const firstSessionDate = firstSessionDateSnap.val();
        // If the url hash and the database hash are the same it means we can proceed
        const { start, end, startMoment, endMoment } = getStartEnd(
          hasMultipleSlotsForDay,
          firstSessionDate
        );

        // Let's get today -> 60 days worth of results for the corporate calendar
        const calendarBookedRef = state.firebaseApp
          .database()
          .ref(`/corporate_accounts/${cID}/calendar`)
          .orderByKey()
          .startAt(start)
          .endAt(end);

        // const calendarBookedSnap = await calendarBookedRef.once('value');

        // const calendarBooked = calendarBookedSnap.val();

        // Let's get the linked space id
        const calendar_space_id_snapshot = await state.firebaseApp
          .database()
          .ref(`/corporate_accounts/${cID}/calendar_space_id`)
          .once('value');
        const calendar_space_id = calendar_space_id_snapshot.val();

        // Now get the availabilities
        const calendar_days_snap = await state.firebaseApp
          .database()
          .ref(`/corporate_accounts/${cID}/calendar_days`)
          .once('value');
        let calendar_days = calendar_days_snap.val();
        if (!calendar_days) {
          console.error('No calendar days');
          alert(
            'Please ask your administrator to set up days availabilities for this company'
          );
          calendar_days = {};
        }

        // From the space id let's get the max occupancy
        const max_capacity_snapshot = await state.firebaseApp
          .database()
          .ref(`/spaces/${calendar_space_id}/max_capacity`)
          .once('value');
        const max_capacity = max_capacity_snapshot.val();
        if (!max_capacity) {
          console.error('This corporate account has no linked space');
        }

        // Now let's build the calendar data state and set id

        const calendarData = {
          calendarBooked: {},
          calendar_days,
          domain_name,
          start,
          end,
          startMoment,
          endMoment,
          isAdmin,
          max_capacity,
          setCalendarData,
        };

        // Long time listener
        calendarBookedRef.on('value', function(snap) {
          const calendarBooked = snap.val() || {};
          console.log(`Got update of a calendar`);
          setCalendarData({ ...calendarData, calendarBooked });
        });

        //console.log(calendarData);
        // setCalendarData(calendarData);
      } else {
        console.error(`Wrong url.`);
        setIsWrongUrl(1);
      }
    }

    if (cID && cHash) {
      // Compute hash and cID
      fetchData();
    } else {
      setIsWrongUrl(1);
    }
  }, [cHash, cID, cKey, state.firebaseApp]);
  // End useEffect()
  if (!cID || !cHash) {
    return <LinkSender />;
  }
  if (isWrongURL) {
    return <LinkSender />;
  }
  //const [snapshots, loading, error] = useList(`/corporate_accounts/calendar`);
  const calendarDisplayer = (
    <CalendarDaysDisplayer
      cID={cID}
      cHash={cHash}
      loggedAs={loggedAs}
      cKey={cKey}
      setIsWrongUrl={setIsWrongUrl}
      {...state}
      {...calendarData}
    />
  );
  const content = calendarData ? calendarDisplayer : <span>Loading...</span>;
  const loggedAsBlock = loggedAs ? (
    <div className="loggedAs">{loggedAs}</div>
  ) : (
    ''
  );
  const user = {
    cID,
    email: loggedAs,
  };
  return (
    <div className="unlogged logged">
      <div className="logoWrapper">
        <img src={logo} alt="Silofit" />
        {loggedAsBlock}
      </div>
      <div className="loginDetails">{content}</div>
    </div>
  );
};

export default MainWrapper;
