import 'react-app-polyfill/ie11';
import React, { Component } from 'react';
import logo from './logo.png';
import './App.scss';
import firebaseConfig from './config';
import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import axios from 'axios';
import MainWrapper, { getCorporateID } from './component/MainWrapper.tsx';
import { handleCorporateCalendarRegistrationEndpoint } from './component/Api';
require('firebase/auth');
//if (!Object.values) Object.values = o => Object.keys(o).map(k => o[k]);
// TODO: Maybe dont put me in react if react can't load no errors?
Sentry.init({
  dsn: 'https://87af065a03624cb6a75527ee464165d1@sentry.io/1874188',
});

let firebaseApp = null;

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} else {
  firebaseApp = firebase.app();
}
window.firebaseApp = firebaseApp;

const query = new URLSearchParams(window.location.search);
class App extends Component {
  state = {
    isSignedIn: null,
    user: null,
    firebaseApp: firebaseApp,
    pathname: document.location.pathname,
  };
  componentDidMount() {
    //require('firebaseui/dist/firebaseui.css');
    // Prewarm the endpoint
    axios.post(handleCorporateCalendarRegistrationEndpoint, {
      warmer: true,
      url: document.location.pathname,
    });
    const that = this;

    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(async user => {
        let state = {
          isSignedIn: !!user,
          user: null,
          token: null,
          firebaseApp,
        };
        if (user) {
          state.user = user;
          const token = await user.getIdToken(true);
          state.token = token;
          Sentry.configureScope(scope => {
            scope.setUser({ email: user.email });
          });
        }
        //debugger;
        that.setState(state);
      });
    firebaseApp.auth().signInAnonymously();
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  loggedInView() {
    return <MainWrapper {...this.state} />;
  }
  loggedOutView() {
    const user = {};
    return (
      <div className="unlogged">
        <div className="logoWrapper">
          <img src={logo} alt="Silofit" />
        </div>
        <p className="loginDetails"></p>
      </div>
    );
  }
  render() {
    const logInOut = this.state.isSignedIn
      ? this.loggedInView()
      : this.loggedOutView();
    return logInOut;
  }
}

export default App;
