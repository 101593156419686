export const handleCorporateCalendarRegistrationEndpoint =
  process.env.NODE_ENV === 'production'
    ? 'https://us-central1-silofit-prod.cloudfunctions.net/handleCorporateCalendarRegistration'
    : 'http://localhost:5001/silofit-dev-2a08d/us-central1/handleCorporateCalendarRegistration';

export const sendCalendarLinkToCorporateAccountEmail =
  process.env.NODE_ENV === 'production'
    ? 'https://us-central1-silofit-prod.cloudfunctions.net/sendCalendarLinkToCorporateAccountEmail'
    : 'http://localhost:5001/silofit-dev-2a08d/us-central1/sendCalendarLinkToCorporateAccountEmail';

//   export const handleCorporateCalendarRegistration =
//   process.env.NODE_ENV === 'production'
//     ? 'https://us-central1-silofit-prod.cloudfunctions.net/handleCorporateCalendarRegistration'
//     : 'https://us-central1-silofit-dev-2a08d.cloudfunctions.net/handleCorporateCalendarRegistration';

// export const sendCalendarLinkToCorporateAccountEmail =
//   process.env.NODE_ENV === 'production'
//     ? 'https://us-central1-silofit-prod.cloudfunctions.net/sendCalendarLinkToCorporateAccountEmail'
//     : 'https://us-central1-silofit-dev-2a08d.cloudfunctions.net/sendCalendarLinkToCorporateAccountEmail';
