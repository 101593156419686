import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../logo.png';
import moment from 'moment-timezone';
import DayBuilder from './DayBuilder';

export const getStartEnd = (
  hasMultipleSlotsForDay = false,
  minimumStartDate = false
) => {
  let format = 'YYYYMMDD';
  let start;
  let startMoment;
  startMoment = moment();
  start = startMoment.format('YYYYMMDD');

  if (minimumStartDate) {
    const minStartMoment = moment(minimumStartDate, format);
    if (minStartMoment.isValid() && minStartMoment.isAfter(start)) {
      start = minimumStartDate;
      startMoment = minStartMoment;
    }
  }
  const endMoment = moment().add(2, 'months');
  let end = endMoment.format('YYYYMMDD');
  if (hasMultipleSlotsForDay) {
    // Use in the .start() for the query
    start = start + '00000000';
    end = end + '99999999';
  }
  return { start, end, startMoment, endMoment };
};

const CalendarDaysDisplayer = state => {
  console.log(state);
  const { start, end, startMoment, endMoment, calendar_days } = state;
  const own_email_from_state = localStorage.getItem('own_email');

  const [own_email, setOwnEmail] = useState(own_email_from_state);
  console.log(`Own email:`, own_email);
  // https://stackoverflow.com/questions/9129928/how-to-calculate-number-of-days-between-two-dates
  const totalDays = endMoment.diff(startMoment, 'days') + 1; // 7 -> 7 or 7 -> 6
  let datesArray = Array.from(Array(totalDays), (x, i) =>
    moment(start, 'YYYYMMDD').add(i, 'days')
  );

  const daysKeys = Object.keys(calendar_days);
  //console.log(datesArray);
  datesArray = datesArray.filter(momentDay => {
    const day = momentDay.format('ddd');
    return daysKeys.includes(day);
  });
  // For each date render the day with space.max_occupancy timeslots
  const datesToDay: any = [];
  datesArray.forEach((momentDay, i) => {
    const day = momentDay.format('ddd');
    const hasDay = daysKeys.includes(day);
    // Valid day
    if (hasDay) {
      const daySlots = calendar_days[day];
      if (Array.isArray(daySlots)) {
        // We have multiple slots for this day
        daySlots.forEach((daySlot, j) => {
          datesToDay.push(
            <DayBuilder
              key={'builder' + i + j}
              own_email={own_email}
              setOwnEmail={setOwnEmail}
              {...state}
              date={momentDay}
              daySlot={daySlot}
            />
          );
        });
      } else {
        datesToDay.push(
          <DayBuilder
            key={'builder' + i}
            own_email={own_email}
            setOwnEmail={setOwnEmail}
            {...state}
            date={momentDay}
          />
        );
      }
    }
  });
  return <div>{datesToDay}</div>;
};

export default CalendarDaysDisplayer;
